/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.cdnfonts.com/css/ageo-personal-use?styles=30185,30184,30181,30182,30183,30178,30179,30180");
@import '~ngx-org-chart/_theming.scss';

// set default font
*, html,body {
  font-family: 'Roboto', sans-serif;
      margin: 0px;
    padding: 0px;
}

button:focus {
  outline: none;
}

input.input {
  height: 30px;
  font-size: 18px;
  background-color: transparent;
  border: none;
}

input.input:focus {
  outline: none;
}
.relative{
  position: relative;
}
.absolute{
  position: absolute;
}

.ant-modal-body {
  padding: 0 !important;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.mat-expansion-panel:not([class*=mat-elevation-z]) {
   box-shadow: none;
}
.search-ico {
  width: 16.56px;
  height: 16.56px;
  position: absolute;
  top: 12px;
  left: 13px;
}
.search-container{
  position: relative;
  float: right;
  margin-bottom: 24px;
}

